import { Component, OnDestroy, OnInit } from "@angular/core";
import { getCurrentYear } from "src/app/utils";

@Component({
    selector: 'app-full-layout',
    templateUrl: './full.layout.component.html',
})
export class FullLayoutComponent implements OnInit  {
    year: number = getCurrentYear();

    ngOnInit(): void {}
}