import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { ScriptsComponent } from "./scripts.component";
import { ChatGenesysComponent } from "@app/component/extras/chat-genesys/chat-genesys.component";

@NgModule({
    declarations:[
        ScriptsComponent,
        ChatGenesysComponent
    ],
    imports:[
        CommonModule,
    ],
    exports:[ScriptsComponent, ChatGenesysComponent]
})
export class ScriptModule {}