import { Component, OnInit } from "@angular/core";
import { LangService } from "@app/service/utils";
import { ToolService } from "@app/service/utils";

@Component({
    selector:'app-scripts',
    template:""
})
export class ScriptsComponent implements OnInit {

    domainsEnabled = ['secure.intermundial.com', 'secure-test.intermundial.com'];

    constructor(
        private toolService: ToolService
    ){}

    ngOnInit(): void {
        this.loadScriptProd();
    }

    /**
     * comprobamos que estamos en produccion para cargar scripts
     * @returns void
     */
    private loadScriptProd(){
        if(!this.domainsEnabled.includes(window.location.hostname)) return;
        this.loadScriptCookiebot();
        this.loadScriptGTM();
    }

    /**
     * cargamos el script de GTM
     */
    private loadScriptGTM(){
        const gtmScript = document.createElement('script');
        gtmScript.src = './assets/scripts/gtm-script.js'
        gtmScript.async = true;
        document.head.appendChild(gtmScript);
    }

    /**
     * cargamos el script de cookiebot y banner de consentimiento
     */
    private loadScriptCookiebot(){
        let head = <HTMLDivElement> document.head;
        this.toolService.addScriptInContainer(head, {
            innerHTML: "",
            id: "Cookiebot",
            src: "https://consent.cookiebot.com/uc.js",
            async: true,
            cbid: "21d2f9a1-e67c-49ce-a057-e3f15c411ba4",
            blockingmode: "manual",
            culture: LangService.getLang()
        });
    }

}