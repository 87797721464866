import { ApplicationConfig } from '@angular/core';
import { provideRouter, withViewTransitions } from '@angular/router';
import { HashLocationStrategy, LocationStrategy, PathLocationStrategy } from '@angular/common';
import { HttpClient, provideHttpClient, withInterceptors } from '@angular/common/http';
import { LangService, StorageService, NotificationService, DataService, LoaderService, OriginService } from '@app/service/utils';
import { ApiV5Service } from './services/api';
import { AuthService } from './services/auth';
import { RegisterService } from './services/register/register.service';
import { MenuService } from './components/layouts/menu.service';
import { routes } from './app.routes';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { LayoutModule } from './components/layouts/app.layout.module';
import { LoginGuard } from './guards/login.guard';
import { AccountService } from './services/account';
import { ApiV5AppService } from './services/api/apiv5.app.service';
import { requestInterceptor } from './interceptor/request.interceptor';

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}


export const appConfig: ApplicationConfig = {
  providers: [
    provideRouter(
      routes,
      withViewTransitions({
        skipInitialTransition:true
      })
    ),
    provideHttpClient(withInterceptors([requestInterceptor])),
    TranslateModule.forRoot({
      defaultLanguage: 'es',
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }).providers!,
    LayoutModule,
    // { provide: LocationStrategy, useClass: PathLocationStrategy},
    { provide: 'APP_NAME', useValue: 'Customer Portal' },
    LangService, 
    StorageService, 
    ApiV5Service, 
    ApiV5AppService,
    AuthService,
    NotificationService,
    DataService,
    LoaderService,
    RegisterService,
    MenuService,
    LoginGuard,
    AccountService,
    OriginService
  ]
};
