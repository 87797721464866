import { Component, OnInit } from "@angular/core";
import { MenuService } from "@app/component/layouts/menu.service";
import { AuthService } from "@app/service/auth";
import { Router} from "@angular/router";
import { LangService, LoaderService } from "@app/service/utils";


@Component({
  selector: "app-sidebar",
  templateUrl: "./sidebar.component.html",
})
export class SidebarComponent implements OnInit {

  public user:string = '';

  constructor(
    public menuService: MenuService,
    private authService: AuthService,
    private loader: LoaderService,
    private router: Router
  ) {}

  ngOnInit() {
    this.setUser();
    this.menuService.setMenu();
  }


  private setUser() {
    const userInfo = this.authService.getUserInfo();
    if(userInfo) {
      let nameUser = (userInfo.name.toString()).toLowerCase();
      let surnameUser = (userInfo.surname.toString()).toLowerCase();
      nameUser = nameUser.replace(/(?:^|\s|["'([{])+\S/g, (l: any) => l.toUpperCase());
      surnameUser = surnameUser.replace(/(?:^|\s|["'([{])+\S/g, (l: any) => l.toUpperCase());
      this.user = `${nameUser} ${surnameUser}`;
    }
  }
  
  logout() {
    this.loader.show();
    this.authService.logout()
      .subscribe(
        resp => {
          this.router.navigate([`${LangService.getLang()}/login`]);
        }
      )
    ;
  }
}