import { Component, inject } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { CommonModule } from '@angular/common';
import { getRandomBackground } from "src/app/utils";
import { OriginService, StorageService} from "src/app/services/utils";

import { NotificationIntmContainerComponent } from '@intm-ui/notification';
import { LoaderComponent } from './components/utils/loader/loader.component';
import { SharedModule } from './shared/shared.module';
import { ScriptModule } from './components/utils/scripts/scripts.module';
import { ORIGIN_APP } from './utils/const';
@Component({
    selector: 'app-root',
    standalone: true,
    imports: [
        CommonModule,
        RouterOutlet,
        NotificationIntmContainerComponent,
        LoaderComponent,
        SharedModule,
        ScriptModule
    ],
    providers: [
        StorageService
    ],
    templateUrl: './app.component.html',
    styleUrl: './app.component.scss'
})
export class AppComponent  {
    title = 'InterMundial';
    private originService = inject(OriginService);
    origin = this.originService.origin;
    ORIGIN_APP = ORIGIN_APP;

    constructor(private storage: StorageService) {
        let background: string = getRandomBackground();
        if (background) {
            this.storage.set('background', background);
        }
    }
}
