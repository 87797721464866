<div class="w-[260px] h-screen pt-24 pr-16 pb-24 pl-16 flex flex-col gap-24">
    <div>
        <a class="py-16 w-full">
            <img src="assets/images/logos/logo-horizontal.svg" alt="logo-intm" class="h-24">
        </a>
    </div>
    <div>
        <intm-text-mid-xs>{{user}}</intm-text-mid-xs>
        <div class="hover:cursor-pointer hover:opacity-75 w-fit" (click)="logout()">
            <intm-text-low-xs color="mid">
                {{ 'menu.logout' | translate }}
            </intm-text-low-xs>
        </div>
    </div>
    <div>
        <ul>
            @for(menu of menuService.menu(); track $index){
                @if(menu.link !== ''){
                    <li class="mb-4 rounded-8 cursor-pointer"
                    routerLinkActive="shadow-semantic-primary bg-surface-blue2"
                    [routerLink]="menu.link"
                    [routerLinkActiveOptions]="{ exact: true }"
                    >
                        <div class="w-full px-8 py-6 flex gap-8">
                            <img [src]="menuService.isActive(menu.link) ? menu.iconActive : menu.icon" alt="{{menu.label | translate}}">
                            @if(menuService.isActive(menu.link)){
                                <intm-text-mid-xs  color="semantic-primary">{{menu.label | translate }}</intm-text-mid-xs>
                            }@else {
                                <intm-text-low-s color="mid">{{menu.label | translate }}</intm-text-low-s>
                            }
                        </div>
                    </li>
                }
            }
        </ul>
    </div>
</div>