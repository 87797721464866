import { computed, Injectable, signal } from "@angular/core";
import { IsActiveMatchOptions, Router } from "@angular/router";
import { LangService } from "@app/service/utils";

export interface IMenuItem{
    label: string;
    link: string;
    icon: string;
    iconActive?: string;
    iconBottom?: string;
    iconBottomActive?: string;
}

const MENU_LIST: IMenuItem[] = [
    {
        label: 'menu.seguros',
        link: '',
        icon: 'assets/images/icons/menu/helth_insurance.svg',
        iconActive: 'assets/images/icons/menu/helth_insurance_active.svg',
        iconBottom: 'assets/images/icons/menu/helth_insurance_b.svg',
        iconBottomActive: 'assets/images/icons/menu/helth_insurance_active_b.svg',
    },
    {
        label: 'menu.siniestros',
        link: '',
        icon: 'assets/images/icons/menu/warning.svg',
        iconActive: 'assets/images/icons/menu/warning_active.svg',
        iconBottom: 'assets/images/icons/menu/warning_b.svg',
        iconBottomActive: 'assets/images/icons/menu/warning_active_b.svg'
    },
    {
        label: 'menu.ayuda',
        link: '',
        icon: 'assets/images/icons/menu/help.svg',
        iconActive: 'assets/images/icons/menu/help_active.svg',
        iconBottom: 'assets/images/icons/menu/help_b.svg',
        iconBottomActive: 'assets/images/icons/menu/help_active_b.svg'
    },
    {
        label: 'menu.cuenta',
        link: '',
        icon: 'assets/images/icons/menu/user.svg',
        iconActive: 'assets/images/icons/menu/user_active.svg',
        iconBottom: 'assets/images/icons/menu/user_b.svg',
        iconBottomActive: 'assets/images/icons/menu/user_active_b.svg'
    }
];

@Injectable({
    providedIn: 'root'
})
export class MenuService {
    
    #menu = signal<IMenuItem[]>([]);

    public menu = computed(()=> this.#menu());

    constructor(
        private router: Router,
    ){}

    public setMenu():void{
        MENU_LIST[0].link = `/${LangService.getLang()}/purchases`;
        MENU_LIST[1].link = `/${LangService.getLang()}/claims`;
        MENU_LIST[2].link = `/${LangService.getLang()}/help`;
        MENU_LIST[3].link = `/${LangService.getLang()}/account`;
        this.#menu.set(MENU_LIST);
    }

    public isActive(link: string): boolean {
        const matchOptions: IsActiveMatchOptions = {
          paths: 'exact',
          queryParams: 'exact',
          fragment: 'ignored',
          matrixParams: 'ignored'
        };
        return this.router.isActive(link, matchOptions);
      }

}