import { inject } from '@angular/core';
import {
  HttpRequest,
  HttpEvent,
  HttpResponse,
  HttpInterceptorFn,
  HttpHandlerFn
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, finalize, tap } from 'rxjs/operators';
import { RequestManagerService } from '@app/service/utils';

export const requestInterceptor: HttpInterceptorFn = (
  request: HttpRequest<any>,
  next: HttpHandlerFn
): Observable<HttpEvent<any>> => {
  const requestMangerService = inject(RequestManagerService);

  if (!requestMangerService.addRequest(request.url)) {
    console.log('Duplicate request:', request.url);
    return requestMangerService.getRequest(request.url)!;
  }

  return next(request).pipe(
    tap((event: HttpEvent<any>) => {
      if (event instanceof HttpResponse) {
        requestMangerService.resolveRequest(request.url, event);
      }
    }),
    catchError((error: any) => {
      requestMangerService.rejectRequest(request.url, error);
      return throwError(() => error);
    }),
    finalize(() => {
      requestMangerService.removeRequest(request.url);
    })
  );
};

