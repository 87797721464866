<intm-container-notification></intm-container-notification>
<intm-loader [urlLogo]="'assets/images/logos/loadingIM.gif'"></intm-loader>
<router-outlet />
<app-scripts/>

<!-- @if(origin() === ORIGIN_APP.INTERMUNDIAL){
    @if((''| isLangES)){
        <app-chat-genesys></app-chat-genesys>
    }
} -->

