import { Component,  inject,  Input} from "@angular/core";
import { LoaderService } from "@app/service/utils/loader.service";


@Component({
    selector: 'intm-loader',
    template: `
        @if ( urlLogo != '' && loaderService.loading()) {
            <div
                class="fade-in-loader fixed top-0 min-h-screen w-screen flex items-center justify-center bg-base z-[100]"
            >
                <img width="48" height="48" [src]="urlLogo">
            </div>
        }
    `,
    standalone: true,
})
export class LoaderComponent {
    public loaderService = inject(LoaderService);

    @Input() urlLogo: string = '';

    constructor() {}

}