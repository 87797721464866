import { Component, OnInit } from "@angular/core";
import { MenuService } from "@app/component/layouts/menu.service";
@Component({
    selector: 'app-bottombar',
    templateUrl: './bottombar.component.html',
})
export class BottombarComponent implements OnInit {
    constructor(
        public menuService: MenuService
    ){}

    ngOnInit(): void {
        this.menuService.setMenu();
    }

}