<div class="w-full px-8 py-12">
    <ul class="grid grid-cols-4 gap-8">
    @for(menu of menuService.menu(); track $index){
        @if(menu.link !== ''){
            <li class="h-[46px]"
            [routerLink]="menu.link"
            [routerLinkActiveOptions]="{ exact: true }"
            >
                <div class="px-8 py-0 flex flex-col gap-4 items-center justify-center">
                    @if(menuService.isActive(menu.link)){
                        <img class="transition-all duration-300 ease-in-out" width="24px" height="24px" src="{{menu.iconBottomActive}}" alt="{{menu.label | translate }}">
                        <intm-text-mid-xs class="transition-all duration-300 ease-in-out" color="semantic-primary">{{menu.label | translate }}</intm-text-mid-xs>
                    }
                    @else{
                        <img class="transition-all duration-300 ease-in-out" width="24px" height="24px" src="{{menu.iconBottom}}" alt="{{menu.label | translate}}">
                        <intm-text-low-s class="transition-all duration-300 ease-in-out" color="mid">{{menu.label | translate }}</intm-text-low-s>
                    }
                </div>
            </li>
        }  
    }
    </ul>
</div>