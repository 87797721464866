<div class="min-h-screen flex">
    <!--sidebar-->
    <div class="w-auto max-w-260 h-screen fixed flex-none bg-alt s:hidden">
        <app-sidebar></app-sidebar>
    </div>
    <!--main content-->
    <div class="flex flex-col w-full">
        <main class="flex flex-1 flex-col justify-start min-w-0 overflow-auto pb-32 pt-32 s:pt-0  ml-[260px] s:ml-0 pl-[120px] pr-[120px] m:pl-32 m:pr-32 s:pl-24 s:pr-24 xs:pl-16 xs:pr-16 s:pb-[70px]">
            <div class="hide s:block  py-16 s:fixed s:bg-base s:w-full s:z-50 border-b border-b-transparent s:px-24 s:left-0 xs:px-16">
                <a class="py-16 w-full">
                    <img src="assets/images/logos/logo-horizontal.svg" alt="logo-intm" >
                </a>
            </div>
            <div class="w-full s:mt-[57px]">
                <router-outlet></router-outlet>
            </div>
        </main>
        <div class="flex flex-row px-32 py-16 bg-base border-t border-t-stroke-low ml-[260px] xs:ml-0 justify-center xs:mb-[70px] xs:text-center">
            <intm-text-low-s [color]="'low'">
                © {{ year }} {{ 'footer.company' | translate }}&nbsp;&nbsp;&nbsp;·&nbsp;&nbsp;&nbsp;<a class="hover:cursor-pointer hover:opacity-75" href="{{'footer.href_privacidad' | translate }}">{{ 'footer.label_privacidad' | translate }}</a>&nbsp;&nbsp;&nbsp;·&nbsp;&nbsp;&nbsp;<a class="hover:cursor-pointer hover:opacity-75" href="{{'footer.href_cookies' | translate }}">{{'footer.label_cookies' | translate }}</a>
            </intm-text-low-s>
        </div>
    </div>
    <!--bottombar-->
    <div class="hide s:block fixed bottom-0 w-full h-[70px] bg-alt rounded-t-16 z-[51]">
        <app-bottombar></app-bottombar>
    </div>
</div>